import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export class TalentScansFilter {

  workValues: string[];
  teamRoles: string[];
  domains: string[];
  search: string;

  constructor() {
    this.workValues = [];
    this.teamRoles = [];
    this.domains = [];
  }

  get workValueIds(): string {
    return this.workValues.length > 0 ? this.workValues.join(',') : undefined;
  }

  get teamRoleIds(): string {
    return this.teamRoles.length > 0 ? this.teamRoles.join(',') : undefined;
  }

  get domainIds(): string {
    return this.domains.length > 0 ? this.domains.join(',') : undefined;
  }
}

@Injectable()
export class TalentScansFilterService {

  filter$: BehaviorSubject<TalentScansFilter>;

  constructor() {
    this.filter$ = new BehaviorSubject<TalentScansFilter>(new TalentScansFilter());
  }
}
