import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { PortalService } from '../../../core/services/portal.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kazi-expectations-filter',
  templateUrl: './expectations-filter.component.html',
  styleUrls: ['./expectations-filter.component.scss']
})
export class ExpectationsFilterComponent implements OnInit, OnDestroy {

  @Input() name: string;
  @Input() type: number;
  @Input() max: number;
  @Input() selection: string[];

  @Output() selectionChange = new EventEmitter<string[]>();

  expectations: { id: string, name: string, selected: boolean }[];
  lang: string;
  canSelect: boolean;
  loading: boolean;

  private subscriptions: Subscription[];

  constructor(private translateService: TranslateService,
              private portalService: PortalService) { }

  ngOnInit() {

    this.lang = this.translateService.currentLang;
    
    if (!this.selection) {
      this.selection = [];
    }

    this.canSelect = this.selection.length < this.max;

    this.loading = true;
    this.subscriptions = [
      this.portalService.expectations$
                        .pipe(
                            filter(x => !!x),
                            map(x => x.filter(exp => exp.expectationTypeId === this.type)),
                            map(x => x.map(exp =>
                              ({ id: exp.id,
                                 name: exp.name[this.lang],
                                 selected: (this.selection.indexOf(exp.id) > -1) })))
                        )
                        .subscribe(expectations => {
                          this.expectations = expectations;
                          this.loading = false;
                        })
    ];
  }

  onToggle($event: Event, expectation: { selected: boolean }, selected: boolean) {

    $event.stopImmediatePropagation();

    let selection = this.expectations.filter(c => c.selected);

    if (selection.length === this.max && selected) {
      return;
    }

    expectation.selected = selected;

    selection = this.expectations.filter(c => c.selected);
    this.canSelect = selection.length < this.max;

    this.selectionChange.emit(selection.map(c => c.id));
  }

  onReset($event: Event) {
    $event.preventDefault();

    this.canSelect = true;

    if (this.expectations.filter(comp => comp.selected).length === 0) {
      return;
    }

    this.expectations.forEach(comp => comp.selected = false);
    this.selectionChange.emit([]);
  }

  getExpectationNameKey(id:string){
    return "expectations."+id+".name"
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
