import { Component, OnInit, OnDestroy } from '@angular/core';
import { IdentifyingClient, JobScanDto, DomainTranslatedDto, ExpectationDto } from '../../shared/api/identifying-api.generated';
import { AccountDto, AdministratorClient } from '../../shared/api/administrator-api.generated';
import { Subscription, combineLatest, BehaviorSubject, of, from } from 'rxjs';
import { filter, switchMap, map, distinctUntilChanged, catchError, mergeMap, debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserSettingsService } from '../../core/services/user-settings.service';
import { PortalService } from '../../core/services/portal.service';
import { ExpectationTranslatedDto } from '../../shared/api/tagging-api.generated';
import { environment } from '../../../environments/environment';
import { BatchActionService, BatchSelectionType } from '../../core/services/batch-action.service';
import { DeleteModalComponent, DeleteModalProps } from '../../shared/components/delete-modal/delete-modal.component';
import { JobScansFilter, JobScansFilterService } from '../jobscans-filter.service';

export type Expectation = ExpectationDto & { expectationTypeId: number, name: string, iconUrl: string };
export type JobScan = JobScanDto & { domains: DomainTranslatedDto[], expectations: Expectation[] };

@Component({
  selector: 'kazi-jobscans',
  templateUrl: './jobscans.component.html',
  styleUrls: ['./jobscans.component.scss']
})
export class JobScansComponent implements OnInit, OnDestroy {

  jobScans: JobScan[];
  totalCount: number;
  sortOn: string;
  actionBarVisible: boolean;
  filter: JobScansFilter;
  loading: boolean;

  private page = 1;
  private pageSize = environment.api.pageSize;
  private fetch$: BehaviorSubject<boolean>;
  private subscriptions: Subscription[];

  constructor(private identifyingClient: IdentifyingClient,
              private administratorClient: AdministratorClient,
              private userSettingsService: UserSettingsService,
              private portalService: PortalService,
              private batchActionService: BatchActionService,
              private modalService: BsModalService,
              private translate: TranslateService,
              private jobScansFilterService: JobScansFilterService) {
    this.jobScans = [];
    this.subscriptions = [];

    this.fetch$ = new BehaviorSubject(false);
    this.sortOn = '-createdOn';
  }

  ngOnInit() {

    const jobScansSub = combineLatest(this.fetch$,
                                         this.jobScansFilterService.filter$,
                                         this.userSettingsService.currentAccount$,
                                         this.portalService.expectations$,
                                         this.portalService.domains$)
        .pipe(
          filter(([fetch, jobScansFilter, account, expectations, domains]) => fetch && !!account && !!expectations && !!domains),
          debounceTime(500),
          switchMap(([fetch, jobScansFilter, account, expectation, domains]:
                     [boolean, JobScansFilter, AccountDto, ExpectationTranslatedDto[], DomainTranslatedDto[]]) => {
              const offset = (this.page - 1) * this.pageSize;
              this.loading = true;
              return this.administratorClient.getJobScansForAccount(
                                        account.id,
                                        undefined,  /* externalRef */
                                        undefined /* shouldHaveExpectations */,
                                        true /* includeExpectations */,
                                        jobScansFilter.teamRoleIds, jobScansFilter.workValueIds,
                                        offset, this.pageSize, this.sortOn, jobScansFilter.search, jobScansFilter.domainIds).pipe(
                map((jobScans: JobScan[]) => {
                  jobScans.forEach(jp => {
                    jp.expectations = this.mapExpectations(jp, expectation);
                    jp.domains = this.mapDomains(jp, domains);
                  });
                  return jobScans;
                }),
                catchError(err => of([]))
              );
            }),
      ).subscribe(jobScans => {
        this.loading = false;
        Array.prototype.push.apply(this.jobScans, jobScans);
        this.fetch$.next(false);
      });

    const accountSub = this.userSettingsService
                            .currentAccount$
                            .pipe(filter(p => !!p), distinctUntilChanged())
                            .subscribe(account => this.reset());

    const batchDeleteSub = this.batchActionService
                               .delete$
                               .pipe(filter(res => res.selectionType === BatchSelectionType.Jobs))
                               .subscribe(res => this.showBatchDeleteConfirmationModal(res.selection));

    const batchActionBarVisibleSub = this.batchActionService
                                         .actionBarVisible$
                                         .subscribe(actionBarVisible => this.actionBarVisible = actionBarVisible);

    const filterSub = this.jobScansFilterService.filter$
                                                   .subscribe(jobScansFilter => {
                                                      this.filter = jobScansFilter;
                                                      this.reset();
                                                    });

    const totalCountSub = combineLatest(this.userSettingsService.currentAccount$,
                                        this.jobScansFilterService.filter$)
                            .pipe(
                              filter(([account, jobScansFilter]) => !!account),
                              switchMap(([account, jobScansFilter]: [AccountDto, JobScansFilter]) =>
                                this.administratorClient.getTotalNumberOfJobScansForAccount(
                                  account.id, undefined /* shouldHaveExpectations */,
                                  jobScansFilter.teamRoleIds, jobScansFilter.workValueIds,
                                  jobScansFilter.search, jobScansFilter.domainIds)
                            )
                          ).subscribe(totalCount => this.totalCount = totalCount);


    this.subscriptions.push(jobScansSub);
    this.subscriptions.push(accountSub);
    this.subscriptions.push(batchDeleteSub);
    this.subscriptions.push(batchActionBarVisibleSub);
    this.subscriptions.push(filterSub);
    this.subscriptions.push(totalCountSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getJobScans() {
    this.page++;
    this.fetch$.next(true);
  }

  sort(sortOn: string) {
    this.sortOn = sortOn;
    this.reset();
  }

  onDelete(jobScan: JobScan) {
    const index = this.jobScans.indexOf(jobScan);
    if (index >= 0) {
      this.jobScans.splice(index, 1);
      this.totalCount--;
    }
  }

  workValuesChanged(workValues: string[]) {
    this.filter.workValues = workValues;
    this.jobScansFilterService.filter$.next(this.filter);
  }

  teamRolesChanged(teamRoles: string[]) {
    this.filter.teamRoles = teamRoles;
    this.jobScansFilterService.filter$.next(this.filter);
  }

  domainsChanged(domains: string[]) {
    this.filter.domains = domains;
    this.jobScansFilterService.filter$.next(this.filter);
  }

  clearSearch() {
    this.filter.search = '';
    this.searchChanged('');
  }

  searchChanged(search: string) {
    this.jobScansFilterService.filter$.next(this.filter);
  }

  private reset() {
    this.batchActionService.deSelectAll();
    this.page = 1;
    this.jobScans.splice(0);
    this.totalCount = 0;
    this.fetch$.next(true);
  }

  private showBatchDeleteConfirmationModal(jobScanIds: Array<string>) {
    const count = jobScanIds.length;
    const header = this.translate.instant('jobscans.delete_multiple_modal_header', { count });
    const message = this.translate.instant('jobscans.delete_multiple_modal_content', {
                                count,
                                accountName: this.userSettingsService.currentAccount$.getValue().name });
    const props = new DeleteModalProps(header, message);
    const initialState = { props };

    this.modalService.show(DeleteModalComponent, { initialState });

    const deleteSub = props.confirm$.subscribe(() => this.deleteJobScans(jobScanIds));
    this.subscriptions.push(deleteSub);
  }

  private deleteJobScans(jobScanIds: Array<string>) {
    this.identifyingClient.deleteJobScans(jobScanIds.join(','))
                          .subscribe(() => {
      this.batchActionService.deSelectAll();
      this.batchActionService.actionBarVisible$.next(false);

      jobScanIds.forEach(id =>  {
        const jobScan = this.jobScans.filter(t => t.id === id)[0];
        this.onDelete(jobScan);
      });
    });
  }

  private mapExpectations(jobScan: JobScan, allExpectations: ExpectationTranslatedDto[]): Expectation[] {
    if (!jobScan.expectations) {
      return [];
    }

    const lang = this.translate.currentLang;
    return jobScan.expectations.map((exp: Expectation) => {
      const translatedExpectation = allExpectations.filter(c => c.id === exp.expectationId)[0];
      exp.expectationTypeId = translatedExpectation.expectationTypeId;
      exp.name = translatedExpectation.name[lang];
      exp.iconUrl = translatedExpectation.iconUrl;
      return exp;
    });
  }

  private mapDomains(jobScan: JobScan, allDomains: DomainTranslatedDto[]): DomainTranslatedDto[] {
    if (!jobScan.domainIds) {
      return [];
    }

    return jobScan.domainIds.map(domainId => allDomains.filter(dom => dom.id === domainId)[0]);
  }
}
