import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ExpectationTranslatedDto, TaggingClient } from '../../shared/api/tagging-api.generated';
import { DomainTranslatedDto, IdentifyingClient } from '../../shared/api/identifying-api.generated';

@Injectable({ providedIn: 'root'})
export class PortalService {

    expectations$: BehaviorSubject<ExpectationTranslatedDto[]>;
    domains$: BehaviorSubject<DomainTranslatedDto[]>;

    constructor(private taggingClient: TaggingClient,
                private identifyingClient: IdentifyingClient) {
        this.expectations$ = new BehaviorSubject(null);
        this.domains$ = new BehaviorSubject(null);

        this.loadExpectations();
        this.loadDomains();
    }

    private loadExpectations() {
        this.taggingClient.getExpectations()
            .subscribe(expectations => this.expectations$.next(expectations));
    }

    private loadDomains() {
        this.identifyingClient.getDomains()
            .subscribe(domains => this.domains$.next(domains));
    }
}
