import { Injectable } from '@angular/core';
import { AccountDto } from '../../shared/api/administrator-api.generated';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {
    currentAccount$: BehaviorSubject<AccountDto>;

    private currentAccountKey = 'currentAccount';
    private subscriptions: Subscription[];

    constructor() {
        this.subscriptions = [];
        this.currentAccount$ = new BehaviorSubject(null);
    }

    clear() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.currentAccount$.next(null);

        localStorage.removeItem(this.currentAccountKey);
    }

    restore() {
        const currentAccountJson = localStorage.getItem(this.currentAccountKey);
        if (currentAccountJson) {
            this.currentAccount$.next(JSON.parse(currentAccountJson));
        }

        this.currentAccount$.pipe(
          filter(account => !!account)
        ).subscribe(account => localStorage.setItem(this.currentAccountKey, JSON.stringify(account)));
    }

    isAccountSelected(): boolean {
        return !!this.currentAccount$.getValue();
    }
}
