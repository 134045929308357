import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

export class DeleteModalProps {    
    headerText: string;
    contentText: string;
    confirm$: Subject<boolean>;

    constructor(headerText: string, contentText: string) {
      this.headerText = headerText;
      this.contentText = contentText;
      this.confirm$ = new Subject();
    }
}

@Component({
  selector: 'kazi-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @Input() props: DeleteModalProps;

  constructor(public modal: BsModalRef) { }

  ngOnInit() {
  }

  cancel() {
    this.modal.hide();
  }

  delete() {    
    this.props.confirm$.next();    
    this.modal.hide();
  }

}
