import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kazi-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})
export class ColumnHeaderComponent implements OnInit {

  private ascending: boolean;
  private currentSortProp: string;

  @Input() columnText: string;
  @Input() sortProp: string;
  @Input()
  set currentSort(sortOn: string) {
    const sort = sortOn;
    this.currentSortProp = sort.substring(1);
    this.ascending = sort.indexOf('+') === 0;

    this.sortActive = this.currentSortProp.indexOf(this.sortProp) >= 0;
    this.ascendingSortIconHidden = this.currentSortProp === this.sortProp && this.ascending === false;
    this.descendingSortIconHidden = this.currentSortProp === this.sortProp && this.ascending === true;
  }

  @Output() sort = new EventEmitter<string>();

  sortActive: boolean;
  ascendingSortIconHidden: boolean;
  descendingSortIconHidden: boolean;

  ngOnInit() {
    this.ascending = false;
  }

  private getSortString() {
    return `${this.ascending ? '+' : '-'}${this.sortProp}`;
  }

  onClick() {
    if (this.sortProp === this.currentSortProp) {
      this.ascending = !this.ascending;
      this.sort.emit(this.getSortString());
      return;
    }

    this.ascending = true;
    this.sort.emit(this.getSortString());
  }
}
