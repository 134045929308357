import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobScansRoutingModule } from './jobscans-routing.module';
import { JobScansComponent } from './jobscans/jobscans.component';
import { SharedModule } from '../shared/shared.module';
import { JobScanComponent } from './jobscan/jobscan.component';
import { JobScansFilterService } from './jobscans-filter.service';

@NgModule({
  declarations: [JobScansComponent, JobScanComponent],
  imports: [
    CommonModule,
    JobScansRoutingModule,
    SharedModule
  ],
  providers: [JobScansFilterService],
  entryComponents: [JobScansComponent]
})
export class JobScansModule { }
