import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import * as R from 'ramda';
import { Subscription } from 'rxjs';

import { ExpectationDto } from '../../api/identifying-api.generated';

type Expectation = ExpectationDto & { expectationTypeId: number; name: string; iconUrl: string };

declare module 'ramda' {
    interface Filter {
        <T>(fn: (value: T) => boolean): (list: ReadonlyArray<T>) => T[];
        <T>(fn: (value: T) => boolean, list: ReadonlyArray<T>): T[];
    }
}

@Component({
    selector: 'kazi-expectations',
    templateUrl: './expectations.component.html',
    styleUrls: ['./expectations.component.scss'],
    providers: [TranslatePipe],
})
export class ExpectationsComponent implements OnInit, OnDestroy {
    top2Expectations: Expectation[];
    otherExpectations: Expectation[];

    top2ExpectationsNames: string;
    otherExpectationsNames: string;

    lang: string;

    translateSubscription: Subscription;

    @Input() expectations: Expectation[];
    @Input() type: number;

    constructor(private translate: TranslateService, private translatePipe: TranslatePipe) {}

    ngOnInit() {
        if (!this.expectations) {
            return;
        }

        const rankedExpectations = R.compose(
            R.sortBy(R.prop('rank')),
            R.filter<Expectation>((comp) => comp.expectationTypeId === this.type)
        )(this.expectations);

        this.top2Expectations = rankedExpectations.slice(0, 2);
        this.top2ExpectationsNames = this.top2Expectations
            .map((comp) => 'expectations.' + comp.expectationId + '.name')
            .map((exp) => this.translatePipe.transform(exp))
            .join(', ');

        if (rankedExpectations.length > 2) {
            this.otherExpectations = rankedExpectations.slice(2);
            this.otherExpectationsNames = this.otherExpectations
                .map((comp) => 'expectations.' + comp.expectationId + '.name')
                .map((exp) => this.translatePipe.transform(exp))
                .join(', ');
        }

        this.translateSubscription = this.translate.onLangChange.subscribe(() => {
            this.top2ExpectationsNames = this.top2Expectations
                .map((comp) => 'expectations.' + comp.expectationId + '.name')
                .map((exp) => this.translatePipe.transform(exp))
                .join(', ');
            this.otherExpectationsNames = this.otherExpectations
                .map((comp) => 'expectations.' + comp.expectationId + '.name')
                .map((exp) => this.translatePipe.transform(exp))
                .join(', ');
        });
    }

    ngOnDestroy(): void {
        this.translateSubscription.unsubscribe();
    }
}
