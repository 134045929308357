import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import { LogLevel } from 'msal';
import { version, adB2c } from './common.settings';

export const environment = {
  production: JSON.parse('true'),   // trick to parse boolean string to boolean
  baseUrl: 'https://admin.kazi.co/',
  msalLogLevel: LogLevel.Error,
  version,
  googleTagManagerId: 'GTM-PJVMH6V',
  adB2c,

  api: {
    subscriptionKey: '2ec24bc2007543dfb1d3b9ad5d4abc07',
    clientId: '5e69ed87-ac4f-4136-93c5-80a41c8e6fc7',
    identifyingUrl: 'https://api.kazi.be/identifying/v2',
    taggingUrl: 'https://api.kazi.be/tagging/v2',
    administratorUrl: 'https://api.kazi.be/admin/v2',
    pageSize: parseInt('30', 10)
  },

  sentry: {
    enabled: JSON.parse('false'),
    dsn: 'https://8ca52909fb334168a6096d83ac2d076a@sentry.io/1425706'
  }
};
