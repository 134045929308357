import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShellRoutingModule } from './shell-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ShellComponent } from './shell/shell.component';
import { BatchActionBarComponent } from './batch-action-bar/batch-action-bar.component';
import { OverviewComponent } from './overview/overview.component';

import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [DashboardComponent, HeaderComponent, SidebarComponent, ShellComponent, BatchActionBarComponent, OverviewComponent],
  imports: [
    CommonModule,
    ShellRoutingModule,
    SharedModule
  ]
})
export class ShellModule { }
