import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BatchActionService } from '../../core/services/batch-action.service';

@Component({
  selector: 'kazi-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {

  actionBarVisible: boolean;
  subscriptions: Subscription[];

  selectionCount = 4;

  constructor(private batchActionService: BatchActionService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    const actionBarVisibleSub = this.batchActionService
                                    .actionBarVisible$
        .subscribe(actionBarVisible => this.actionBarVisible = actionBarVisible);

    this.subscriptions.push(actionBarVisibleSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
