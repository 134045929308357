import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'msal';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';

import { environment } from '../environments/environment';
import { AdministratorClient, UserDto, IUserDto } from './shared/api/administrator-api.generated';
import { AccountsService } from './core/services/accounts.service';

import * as R from 'ramda';

export type WindowWithAgileCrmAndIntercom = Window & {
    _agile: { set_email(email: string): void };
    intercomSettings: object;
    Intercom(action: string): void;
};
export interface MsalIdToken {
    oid: string;
    given_name: string;
    family_name: string;
    name: string;
    emails: string[];
}

@Component({
    selector: 'kazi-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'kazi-adminportal';
    isIframe: boolean;
    private subscriptions: Subscription[];

    constructor(
        private translate: TranslateService,
        private msalService: MsalService,
        private broadcastService: BroadcastService,
        private accountsService: AccountsService,
        private administratorClient: AdministratorClient,
        @Inject('window') private window: WindowWithAgileCrmAndIntercom
    ) {
        this.isIframe = window !== window.parent && !window.opener;
    }

    ngOnInit() {
        this.subscriptions = [];

        // Reset password
        this.subscriptions.push(
            this.broadcastService.subscribe('msal:loginFailure', (payload) => {
                if (payload.errorDesc && payload.errorDesc.indexOf('AADB2C90118') > -1) {
                    this.resetPassword();
                }
            })
        );

        // Re-login after token expired and silent token refresh (via iFrame) failed
        this.subscriptions.push(
            this.broadcastService.subscribe('msal:acquireTokenFailure', (payload) => {
                if (
                    payload.errorDesc.indexOf('consent_required') !== -1 ||
                    payload.errorDesc.indexOf('interaction_required') !== -1 ||
                    payload.errorDesc.indexOf('login_required') !== -1 ||
                    payload.errorDesc.indexOf('Token Renewal Failed') !== -1
                ) {
                    this.msalService.acquireTokenRedirect(environment.adB2c.scopes);
                }
            })
        );

        this.subscriptions.push(
            this.broadcastService.subscribe('msal:acquireTokenSuccess', (payload) => {
                this.updateUser();
                this.setAgileCrmUser(this.msalService.getUser());
                this.setIntercomUser(this.msalService.getUser());
            })
        );

        this.accountsService.loadAccounts();
        this.setIntercomUser(this.msalService.getUser());

        this.translate.addLangs(['nl', 'fr', 'en']);
        this.translate.setDefaultLang('nl');
        this.translate.use('nl');
    }

  // https://feedback.azure.com/forums/169401-azure-active-directory/suggestions/
  // 33494830-go-direct-to-password-reset-from-sign-in-sign-up?page=2&per_page=20
  resetPassword() {
    this.msalService.authority =
    `${environment.adB2c.identityProviderUrl}${environment.adB2c.tenant}/` +
    `${environment.adB2c.passwordResetPolicyId}`;
    this.msalService.loginRedirect(environment.adB2c.scopes, `ui_locales=nl`);
  }

    private updateUser() {
        const msalUser = this.msalService.getUser().idToken as MsalIdToken;
        if (msalUser && msalUser.emails) {
            this.administratorClient
                .updateUser(
                    new UserDto({
                        ssoId: msalUser.oid,
                        firstName: msalUser.given_name,
                        lastName: msalUser.family_name,
                        email: msalUser.emails[0],
                        displayName: msalUser.name,
                    } as IUserDto)
                )
                .subscribe((res) => true); // cold observable
        }
    }

    private setAgileCrmUser(user: User) {
        if (this.window._agile && typeof this.window._agile.set_email === 'function') {
            const idToken = user.idToken as { emails: string[] };
            if (idToken.emails) {
                const userEmail = idToken.emails[0];
                this.window._agile.set_email(userEmail);
            }
        }
    }

    private setIntercomUser(user: User) {
        if (this.window.intercomSettings && this.window.Intercom && typeof this.window.Intercom === 'function' && user) {
            const idToken = user.idToken as { emails: string[] };
            if (idToken.emails) {
                this.window.intercomSettings = R.mergeWith((first, second) => second, this.window.intercomSettings, {
                    email: idToken.emails[0],
                });
                this.window.Intercom('update');
            }
        }
    }   

    ngOnDestroy() {
        this.broadcastService.getMSALSubject().next(1);
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
