import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as R from 'ramda';

import { PortalService } from '../../../core/services/portal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'kazi-domains-filter',
  templateUrl: './domains-filter.component.html',
  styleUrls: ['./domains-filter.component.scss']
})
export class DomainsFilterComponent implements OnInit, OnDestroy {

  @Input() name: string;
  @Input() selection: string[];

  @Output() selectionChange = new EventEmitter<string[]>();

  domains: { id: string, name: string, selected: boolean }[];
  lang: string;
  loading: boolean;

  private subscriptions: Subscription[];

  constructor(private translateService: TranslateService,
              private portalService: PortalService) { }

  ngOnInit() {
    this.lang = this.translateService.currentLang;
    
    if (!this.selection) {
      this.selection = [];
    }

    this.loading = true;
    this.subscriptions = [
      this.portalService.domains$
                        .pipe(
                            filter(x => !!x),
                            map(x => x.map(dom =>
                              ({ id: dom.id,
                                 name: dom.name[this.lang],
                                 selected: (this.selection.indexOf(dom.id) > -1) }))),
                            map(x => R.sortBy(R.prop('name'))(x))
                        )
                        .subscribe(domains => {
                          this.domains = domains;
                          this.loading = false;
                        })
    ];
  }

  onToggle($event: Event, domain: { selected: boolean }, selected: boolean) {

    $event.stopImmediatePropagation();

    domain.selected = selected;

    const selection = this.domains.filter(c => c.selected);
    this.selectionChange.emit(selection.map(c => c.id));
  }

  onReset($event: Event) {
    $event.preventDefault();

    if (this.domains.filter(comp => comp.selected).length === 0) {
      return;
    }

    this.domains.forEach(comp => comp.selected = false);
    this.selectionChange.emit([]);
  }

  getDomainNameKey(id:string){
    return "domains."+id+".name"
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
