import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { AccountsService } from '../../core/services/accounts.service';

@Component({
  selector: 'kazi-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit, OnDestroy {

  hasAccounts: boolean;
  loaded: boolean;
  subscriptions: Subscription[];

  constructor(private msalService: MsalService,
              private accountsService: AccountsService) { }

  ngOnInit() {

    this.subscriptions = [
      this.accountsService
          .accountsLoaded$
          .subscribe(loaded => this.loaded = loaded),
      this.accountsService
          .accounts$
          .subscribe(accounts => this.hasAccounts = accounts.length > 0)
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get isLoggedIn(): boolean {
    return !!this.msalService.getUser();
  }

  login($event: Event) {
    $event.preventDefault();
    this.msalService.loginRedirect(environment.adB2c.scopes, `ui_locales=nl`);
  }
}
