import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TalentScansRoutingModule } from './talentscans-routing.module';
import { TalentScansComponent } from './talentscans/talentscans.component';
import { SharedModule } from '../shared/shared.module';
import { TalentScanComponent } from './talentscan/talentscan.component';

import { TalentScansFilterService } from './talentscans-filter.service';

@NgModule({
  declarations: [TalentScansComponent, TalentScanComponent],
  imports: [
    CommonModule,
    TalentScansRoutingModule,
    SharedModule
  ],
  providers: [ TalentScansFilterService ],
  entryComponents: [TalentScansComponent]
})
export class TalentScansModule { }
