import {Directive, Input, HostBinding, HostListener} from '@angular/core';

@Directive({
    // tslint:disable-next-line
    selector: 'img[default]'    
  })
 export class ImageDirective {

    @Input() @HostBinding('src') src: string;
    @Input() default: string;

    @HostListener('error') updateUrl() {
      this.src = this.default;
    }

    @HostListener('load') load() {
    }
  }