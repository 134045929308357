import * as Sentry from '@sentry/browser';
import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.sentry.enabled) {
      // const eventId =
      Sentry.captureException(error.originalError || error);
      // Sentry.showReportDialog({ eventId });
    } else {
      console.error('*** ERROR ***', error.originalError || error);
    }

  }
}
