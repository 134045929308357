import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserSettingsService } from '../../core/services/user-settings.service';
import { AccountDto } from '../../shared/api/administrator-api.generated';
import { Subscription } from 'rxjs';
import { versionInfo } from '../../../environments/version-info';
import { AccountsService } from '../../core/services/accounts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'kazi-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    currentAccount: AccountDto;
    subscriptions: Subscription[];

    version: string;
    revision: string;

    hasAccounts: boolean;

    constructor(
        private userSettingsService: UserSettingsService,
        private accountsService: AccountsService,
        private translate: TranslateService
    ) {
        this.subscriptions = [];

        this.version = versionInfo.version;
        this.revision = versionInfo.revision;
        translate.addLangs(['en', 'nl', 'fr']);
        translate.setDefaultLang('nl');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|nl|fr/) ? browserLang : 'nl');
    }

    getLangs() {
        return this.translate.getLangs();
    }

    ngOnInit() {
        this.subscriptions = [
            this.userSettingsService.currentAccount$.subscribe((account) => (this.currentAccount = account)),
            this.accountsService.accounts$.subscribe((accounts) => (this.hasAccounts = accounts.length > 0)),
        ];
    }

    clickLanguage(lang: string) {
        this.translate.use(lang);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
