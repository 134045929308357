import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BatchActionService } from '../../core/services/batch-action.service';
import { UserSettingsService } from '../../core/services/user-settings.service';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'kazi-batch-action-bar',
  templateUrl: './batch-action-bar.component.html',
  styleUrls: ['./batch-action-bar.component.scss']
})
export class BatchActionBarComponent implements OnInit, OnDestroy {

  actionBarVisible: boolean;
  subscriptions: Subscription[];
  selectionCount: number;
  decoratorClass: string;

  constructor(private batchActionService: BatchActionService,
              private userSettingsService: UserSettingsService,
              private router: Router) {
    this.subscriptions = [];
  }

  ngOnInit() {

    this.setDecoratorClass(this.router.url);

    const urlSub = this.router.events.pipe(
      map(() => this.router.url),
      distinctUntilChanged()
    ).subscribe(url => this.setDecoratorClass(url));
    this.subscriptions.push(urlSub);

    const actionBarVisibleSub = this.batchActionService
                                    .actionBarVisible$
        .subscribe(actionBarVisible => this.actionBarVisible = actionBarVisible);

    const selectedItemsSub = this.batchActionService
                                 .selectedItems$
        .subscribe(selectedItems => this.selectionCount = selectedItems.length);

    const currentAccountSub = this.userSettingsService.currentAccount$.subscribe(account => this.batchActionService.deSelectAll());

    this.subscriptions.push(actionBarVisibleSub);
    this.subscriptions.push(selectedItemsSub);
    this.subscriptions.push(currentAccountSub);
  }

  close() {
    this.batchActionService.deSelectAll();
  }

  deleteBatch() {
    this.batchActionService.delete();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private setDecoratorClass(url: string) {
    this.decoratorClass = url === '/talents'
                                ? 'delete-multiple-talents'
                                : 'delete-multiple-jobs';
  }
}
