import { Injectable } from '@angular/core';
import { AccountDto, AdministratorClient } from '../../shared/api/administrator-api.generated';
import { BehaviorSubject, Subscription, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { UserSettingsService } from './user-settings.service';

import * as R from 'ramda';

@Injectable({
    providedIn: 'root'
})
export class AccountsService {
    accounts$: BehaviorSubject<AccountDto[]>;
    accountsLoaded$: BehaviorSubject<boolean>;

    constructor(private administratorClient: AdministratorClient,
                private userSettingsService: UserSettingsService) {
      this.accounts$ = new BehaviorSubject<AccountDto[]>([]);
      this.accountsLoaded$ = new BehaviorSubject(false);
    }

    loadAccounts() {
      const getAccounts$ = this.administratorClient
                               .getMyActiveAccounts()
                               .pipe(catchError(error => of([])));

      getAccounts$.subscribe(accounts => {

        const sortAccountsByName = R.sortBy(R.compose(R.toLower, R.prop('name')));
        const sortedAccounts = sortAccountsByName(accounts as readonly Record<"name", unknown>[]) as AccountDto[];
        this.accounts$.next(sortedAccounts);

        this.userSettingsService.restore();
        if (!this.userSettingsService.isAccountSelected() &&
            sortedAccounts.length > 0) {
          this.userSettingsService.currentAccount$.next(sortedAccounts[0]);
        }

        this.accountsLoaded$.next(true);
      });

      return getAccounts$;
    }
}
