import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { JobScansComponent } from './jobscans/jobscans.component';
import { ReportComponent } from '../shared/components/report/report.component';

export const shellRoutes: Routes = [
    { path: 'jobs', component: JobScansComponent, canActivate: [MsalGuard] },
    { path: 'jobs/:id/report', component: ReportComponent, canActivate: [MsalGuard] },
    { path: 'jobs/:id/compare/:id2', component: ReportComponent, canActivate: [MsalGuard] },
];

const fullScreenRoutes: Routes = [{ path: 'jobs/:id/report/print', component: ReportComponent, canActivate: [MsalGuard] }];

@NgModule({
    imports: [RouterModule.forChild(fullScreenRoutes)],
    exports: [RouterModule],
})
export class JobScansRoutingModule {}
