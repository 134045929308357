import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

import { User } from 'msal';
import { AccountDto } from '../../shared/api/administrator-api.generated';
import { UserSettingsService } from '../../core/services/user-settings.service';
import { AccountsService } from '../../core/services/accounts.service';

@Component({
  selector: 'kazi-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  currentUser: User;
  accounts: AccountDto[];

  private subscriptions: Subscription[];

  constructor(private msalService: MsalService,
              private userSettingsService: UserSettingsService,
              private accountsService: AccountsService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.currentUser = this.msalService.getUser();

    this.subscriptions.push(
      this.accountsService.accounts$.subscribe(accounts => this.accounts = accounts)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  login() {
    this.msalService.loginRedirect(environment.adB2c.scopes, `ui_locales=nl`);
  }

  logout() {
    this.userSettingsService.clear();
    this.msalService.logout();
  }

  changeToAccount(account: AccountDto) {
    this.userSettingsService.currentAccount$.next(account);
  }

  isAccountSelected(account: AccountDto) {
    const currentAccount = this.userSettingsService.currentAccount$.getValue();
    return !!currentAccount && currentAccount.id === account.id;
  }

  get isLoggedIn(): boolean {
    return !!this.msalService.getUser();
  }
}
