export const version = 'ADM 1.0.0';

export const googleTagManagerId = 'GTM-PJVMH6V';

export const adB2c = {
    identityProviderUrl : 'https://kazib2c.b2clogin.com/',
    tenant: 'kazib2c.onmicrosoft.com',
    signUpSignInPolicyId: 'B2C_1_signin_signup_policy',
    passwordResetPolicyId: 'B2C_1_password_reset_policy',
    identityProviderVersion: 'V2.0',
    scopes: [
      'https://kazib2c.onmicrosoft.com/kazi-api/read:users',
      'https://kazib2c.onmicrosoft.com/kazi-api/write:users',
      'https://kazib2c.onmicrosoft.com/kazi-api/read:jobscans',
      'https://kazib2c.onmicrosoft.com/kazi-api/write:jobscans',
      'https://kazib2c.onmicrosoft.com/kazi-api/read:expectations',
      'https://kazib2c.onmicrosoft.com/kazi-api/read:clusters',
      'https://kazib2c.onmicrosoft.com/kazi-api/read:accounts',
      'https://kazib2c.onmicrosoft.com/kazi-api/read:domains',
      'https://kazib2c.onmicrosoft.com/kazi-api/read:clients',
      'https://kazib2c.onmicrosoft.com/kazi-api/read:talentscans',
      'https://kazib2c.onmicrosoft.com/kazi-api/write:talentscans'
    ]
  };
