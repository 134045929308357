import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

import { TalentScan } from '../talentscans/talentscans.component';
import { DeleteModalComponent, DeleteModalProps } from '../../shared/components/delete-modal/delete-modal.component';
import { UserSettingsService } from '../../core/services/user-settings.service';
import { IdentifyingClient } from '../../shared/api/identifying-api.generated';
import { BatchActionService } from '../../core/services/batch-action.service';

@Component({
    selector: 'kazi-talentscan',
    templateUrl: './talentscan.component.html',
    styleUrls: ['./talentscan.component.scss'],
    providers: [TranslatePipe],
})
export class TalentScanComponent implements OnInit, OnDestroy {
    workValuesExpectationTypeId = 1;
    teamRolesExpectationTypeId = 2;

    name: string;
    domains: string;
    subscriptions: Subscription[];
    hasExpectations: boolean;

    translationSubscription: Subscription;

    @Input() talent: TalentScan;
    @Output() deleted = new EventEmitter<TalentScan>();

    constructor(
        private identifyingClient: IdentifyingClient,
        private router: Router,
        private translate: TranslateService,
        private userSettingsService: UserSettingsService,
        private modalService: BsModalService,
        private batchActionService: BatchActionService,
        private translatePipe: TranslatePipe
    ) {
        this.subscriptions = [];
    }

    ngOnInit() {
        this.name = this.getName();
        this.domains = this.talent.domains
            .map((dom) => 'domains.' + dom.id + '.name')
            .map((d) => this.translatePipe.transform(d))
            .join(', ');
        this.hasExpectations = this.talent.expectations.length === 14;

        this.translationSubscription = this.translate.onLangChange.subscribe(() => {
            this.domains = this.talent.domains
                .map((dom) => 'domains.' + dom.id + '.name')
                .map((d) => this.translatePipe.transform(d))
                .join(', ');
        });
    }

    getName() {
        if (this.talent.firstName || this.talent.lastName) {
            return `${this.talent.firstName} ${this.talent.lastName}`.trim();
        }

        return this.talent.externalRef || '-';
    }

    select() {
        this.batchActionService.toggleItem(this.talent.id);
    }

    isSelected(): boolean {
        return this.batchActionService.selection.indexOf(this.talent.id) > -1;
    }

    showReport($event: Event, talentId: string) {
        $event.preventDefault();
        $event.stopImmediatePropagation();

        this.router.navigate(['talents', talentId, 'report']);
    }

    printReport($event: Event, talentId: string) {
        $event.preventDefault();
        $event.stopImmediatePropagation();

        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/talents/${talentId}/report/print/${this.translate.currentLang}`])
        );

        window.open(url, '_blank');
    }

    showDeleteConfirmationModal($event, talentId: string) {
        $event.preventDefault();
        $event.stopImmediatePropagation();

        const header = this.translate.instant('talents.delete_1_modal_header');
        const message = this.translate.instant('talents.delete_1_modal_content', {
            accountName: this.userSettingsService.currentAccount$.getValue().name,
        });
        const props = new DeleteModalProps(header, message);
        const initialState = { props };

        this.modalService.show(DeleteModalComponent, { initialState });

        const deleteSub = props.confirm$
            .pipe(switchMap(() => this.identifyingClient.deleteTalentScans(talentId)))
            .subscribe(() => this.deleted.next(this.talent));

        this.subscriptions.push(deleteSub);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.translationSubscription.unsubscribe();
    }
}
